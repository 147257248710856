import React from "react";

const MetAInsightsPage = () => {
  return (
    <div style={{margin:"4rem"}}>
      <h1 style={{fontWeight:"500"}}>MetAInsights</h1>
      <div>
        <h3>4 KEY BUILDING BLOCKS</h3>
        <p>Manage Products : Data Domain Mapping </p>
        <p> Manage Classification: Data Classifications & Policies </p>
        <p>Mange Metadata : Data Tool for dynamically crawling data metadata</p>
        <p> Data Organization Participants like data owner, data stewards</p>
      </div>
      <div>
        <h3> DISCOVER DATA</h3>
        <p>Quickly find data assets using key words, tags </p>
        <p>
          Filter Search results by categories like data owner, data asset type
        </p>
      </div>
      <div>
        <h3>UNDERSTAND METADATA</h3>
        <p>Captures table and column definition. </p>
        <p>Captures data owner, data steward names</p>
        <p>Captures column names, data types, classification and sensitivity</p>
      </div>
      <div>
        <h3> CONTROL DATA QUALITY</h3>
        <p>
          Captures data quality like uniqueness, completeness, compliance and
          score{" "}
        </p>
      </div>
      <div>
        <h3>DATA LINEAGE</h3>
        <p>
          {" "}
          Captures detail tracking of data journey from source to its
          destination including all transformations and processes through the
          journey.{" "}
        </p>
        <p>
          Ensures data transparency, enabling user trust and audit how the data
          is created, modified and used.
        </p>
      </div>
    </div>
  );
};

export default MetAInsightsPage;
