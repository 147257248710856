import React from "react";
import { MetAInsightsPageImg1, MetAInsightsPageImg2 } from "../../assets";

const DataSculptPage = () => {
  return (
    <div style={{margin:"4rem"}}>
      <h1 style={{fontWeight:"500"}}>DataSculpt</h1>
      <div>
        <img src={MetAInsightsPageImg2} alt="metainsights image 2" />
      </div>
      <div>
        <h3>
          With DATASCULPT, organizations can effortlessly ingest, cleanse,
          transform and publish enterprise-grade data products. It accelerates
          digital growth by streamlining and simplifying the data pipeline
          creations
        </h3>
      </div>
      <div>
        <img src={MetAInsightsPageImg1} alt="metainsights image 1" />
      </div>
    </div>
  );
};

export default DataSculptPage;
