import React, { useState } from "react";
import ProductCardData from "../../data/ProductCardData";
import "./style.css";
import { prodsample3 } from "../../assets";

const ProductsCards = () => {
  // const [expandedCard, setExpandedCard] = useState();
  const [prd, setPrd] = useState(ProductCardData[0]?.id);

  return (
    // <div className="relative mx-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
    //   {/* Product Cards */}
    //   {ProductCardData.map((prod) => (
    //     <div
    //       key={prod.id}
    //       className={
    //         expandedCard !== prod.id
    //           ? "bg-[#e6f4ff] p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer card"
    //           : "bg-[#e6f4ff] p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer prodCard-card-expanded"
    //       }
    //       onClick={() => {
    //         if (expandedCard !== prod.id) {
    //           setExpandedCard(prod.id);
    //         } else {
    //           setExpandedCard();
    //         }
    //       }}
    //     >
    //       <div className="flex justify-center mb-4">
    //         <div className="text-4xl text-blue-500">
    //           <i className="fas fa-shopping-cart"></i>
    //         </div>
    //       </div>
    //       <h3 className="text-xl font-semibold text-center text-gray-800 mb-2">
    //         {prod.title}
    //       </h3>
    //       <p className="text-center text-gray-600">{prod.description}</p>
    //       {expandedCard === prod.id && (
    //         <div class="prodCard-card-content mt-4 overflow-hidden transition-all duration-500">
    //           <p class="text-center text-gray-600">
    //             This expanded section provides more details about Product One.
    //             It includes additional specifications, pricing details, and
    //             customer reviews.
    //           </p>
    //         </div>
    //       )}
    //     </div>
    //   ))}
    // </div>
    <section className="prodCard-ind-overview">
      <div className="prodCard-ind-big-card">
        <div className="prodCard-ind-big-container">
          {ProductCardData.map((prod) => (
            <div
              className={prd === prod.id && "prodCard-border-purple"}
              onClick={() => {
                setPrd(prod.id);
              }}
            >
              <p>{prod.title}</p>
              {prd === prod.id && (
                <p style={{ margin: "0" }}>{prod.description}</p>
              )}
            </div>
          ))}
        </div>
         
          <img
            src={ProductCardData[prd-1].image}
            alt="prodsample3"
            style={{ width: "60%", height: "100%", transition: "0.3s" }}
          />

        {/* <div style={{ width: "800px", height: "100%", transition: "0.3s", color:"#000000", margin:"auto" }}>
          <h1>{prd}</h1>
        </div> */}
      </div>
    </section>
  );
};

export default ProductsCards;
