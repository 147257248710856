import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Dashboard from "./components/Dashboard"
import DataEnggAna from "./components/DataEnggAna"
import HomePage from "./components/HomePage"
import Login from "./components/Login"
import Metainsights from "./components/Products/Metainsights"
import UnderConstruction from "./components/UnderConstruction"
import MetAInsightsPage from "./components/ProductsPages/MetAInsightsPage"
import DataSculptPage from "./components/ProductsPages/DataSculptPage"
import IntrosightPage from "./components/ProductsPages/IntrosightPage"
import JarvisPage from "./components/ProductsPages/JarvisPage"

function App() {
  // const token = localStorage.getItem('accessToken');

  // if (!token) {
  //   return <Signin />
  // }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/Metainsights" element={<Metainsights />} />
          {/* <Route exact path="/Metamorph" element={<Metamorph />} /> */}
          {/* <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Dashboard" element={<Dashboard />} />
          <Route exact path="/DataEnggAna" element={<DataEnggAna />} /> */}
          <Route exact path="/MetAInsightsPage" element={<MetAInsightsPage/>} />
          <Route exact path="/DataSculptPage" element={<DataSculptPage/>} />
          <Route exact path="/IntrosightPage" element={<IntrosightPage/>} />
          <Route exact path="/JarvisPage" element={<JarvisPage/>} />
          
          <Route exact path="*" element={<UnderConstruction />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App

{
  /* <Navbar />
    <HomePage />
    <Footer /> */
}
