import React from "react";
import { Card, Space } from "antd";

const IntrosightPage = () => {
  return (
    <div style={{margin:"4rem"}}>
      <h1 style={{fontWeight:"500"}}>Introsight</h1>
      <div>
        <h3>
          With INTROSIGHT, organizations can effortlessly publish
          enterprise-grade data products and accelerates digital growth.
        </h3>
      </div>
      <div>
        <h3>What is Semantic Layer ?</h3>
        <p>
          A semantic layer is a standardized framework that organizes and
          abstracts organizational knowledge and serves as a connector for all
          organization knowledge assets
        </p>
        <Space direction="horizontal" size={16}>
          <Card
            title="Cloud Data"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>Card content</p>
            <p>Card content</p>
            <p>Card content</p>
          </Card>
          <Card
            // size="small"
            title="Semantic Layer"
            style={{
              width: 500,
              height: 300,
            }}
          >
            <p>Data Democratization & Governance</p>
            <p>Data Modeling and Metric Layer</p>
            <p>Arvis, Enabling Gen AI with Business Context</p>
          </Card>
          <Card
            // size="small"
            title="Insight Layer"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>Card content</p>
            <p>Card content</p>
            <p>Card content</p>
          </Card>
        </Space>
      </div>
      <div>
        <h3>What does Semantic Layer Enable ?</h3>
        <Space direction="horizontal" size={16}>
          <Card
            title="Standardizing"
            style={{
              width: 500,
            }}
          >
            <p>
              A common vocabulary for seamless information exchange within the
              organization
            </p>
          </Card>
          <Card
            // size="small"
            title="Utilizing AI & LLM’s "
            style={{
              width: 500,
            }}
          >
            <p>
              LLMs and other AI models achieve enhanced fidelity and
              substantially mitigate the generation of inaccurate or
              hallucinated responses.
            </p>
          </Card>
        </Space>
        <Space direction="horizontal" size={16}>
          <Card
            // size="small"
            title="Connecting Information"
            style={{
              width: 500,
            }}
          >
            <p>
              Structured data and content bridge human comprehension and
              Inconsistent Metrics and Definitions Complexity for Business Users
              machine processing effortlessly.
            </p>
          </Card>
          <Card
            // size="small"
            title="Reporting"
            style={{
              width: 500,
            }}
          >
            <p>
              Semantic Layer organizes scattered information into a centralized
              hub, making it accessible regardless of format or origin.
            </p>
          </Card>
        </Space>
      </div>
      <div>
        <h3>What does Semantic Layer Solve ?</h3>
        <Space direction="horizontal" size={16}>
          <Card
            title="Data Silos"
            style={{
              width: 300,
            }}
          ></Card>
          <Card
            // size="small"
            title="Inconsistent Metrics and Definitions"
            style={{
              width: 300,
            }}
          ></Card>
          <Card
            // size="small"
            title=" Complexity for Business Users"
            style={{
              width: 300,
            }}
          ></Card>
          <Card
            // size="small"
            title="AI Hallucination"
            style={{
              width: 300,
            }}
          ></Card>
        </Space>
      </div>
    </div>
  );
};

export default IntrosightPage;
