import React from "react";
import { Card, Space } from "antd";
import { JarvisPageImg1 } from "../../assets";

const JarvisPage = () => {
  return (
    <div style={{ margin: "4rem" }}>
      <h1 style={{ fontWeight: "500" }}>TALK TO DATA</h1>
      <div>
        <h3>
          Arvis, an intelligent AI assistant crafted to refine data exploration,
          uncover insights, and enable impactful insights for digital
          transformation.
        </h3>
      </div>
      <div>
        <h3>What is Arvis ?</h3>
        <Space direction="horizontal" size={16}>
          <Card
            title="Role"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>Arvis, an ai assistant on your team</p>
          </Card>
          <Card
            // size="small"
            title="Knowledge"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>Data this is required for Arvis to be successful</p>
          </Card>
          <Card
            // size="small"
            title="Action"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>Goals Arvis will fullfil</p>
          </Card>
          <Card
            // size="small"
            title="Guardrail"
            style={{
              width: 300,
              height: 300,
            }}
          >
            <p>The guidelines Arvis can operate</p>
          </Card>
        </Space>
      </div>
      <div>
        <h3>Let’s take a deeper look !</h3>
        <div>
        <img src={JarvisPageImg1} alt="Jarvis image 2" />
      </div>
      </div>
    </div>
  );
};

export default JarvisPage;
