import { BookOpen, Code2, Rocket, PaintBucket } from "lucide-react";
import { productCardImage1, productCardImage2 } from "../assets";

const ProductCardData = [
  {
    id: 1,

    title: "MetAInsights",
    description:
      "A unified 360&deg; metadata tool, helps in transforming data discovery, streamline governance and collaboration to empower your team.",
    icon: <PaintBucket className="w-16 h-20 text-violet-500" />,
    image: productCardImage1,
  },
  {
    id: 2,
    title: "DataSculpt",
    description:
      "Unlock the power of your data with DataSculpt! Tailor your transformations to reveal insights that drive smart, strategic decisions.",
    icon: <Code2 className="w-16 h-20 text-blue-600" />,
    image: productCardImage2,
  },
  {
    id: 3,
    title: "IntroSight",
    description:
      "A semantic product that transforms complex data into a user-friendly, enriched model, empowering business users to easily understand and leverage insights",
    icon: <Rocket className="w-16 h-20 text-green-400" />,
    image: productCardImage1,
  },
  {
    id: 4,
    title: "AI Assistant",
    description:
      "Arvis AI Assistant, Fueling your data journey with intelligence",
    icon: <BookOpen className="w-16 h-20 text-yellow-500" />,
    image: productCardImage1,
  },
];

export default ProductCardData;
